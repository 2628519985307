//= includes/mousewheel.js
//= includes/jcf.js
//= includes/jcf.textarea.js
//= includes/jcf.scrollable.js
//= includes/mousewheel.js

(function ($, window, document) {
  'use strict';
  var page = {
    init: function () {
      page.noDev();
      page.mainNav();
      page.customScroll();
      page.resetForm();
      page.stickyHeader();
      page.customForm();
      page.checkEdgeBrowser();

    },
    noDev: function () {
      if ((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
        $('.no-dev').removeClass('no-dev');
      }
    },
    isDev: function () {
      return !$('html').hasClass('no-dev') || window.innerWidth < 1280;
    },
    stickyHeader: function () {
      var target = $('.header_, .main-nav');
      if ($(window).scrollTop() > 10) {
        target.addClass('scrolled');
      } else {
        target.removeClass('scrolled');
      }
    },
    mainNav: function () {
      var mainWrap = $('body');
      var header = $('.header_');
      var menuBtn = header.find('.menu-btn');
      var mainNav = $('.main-nav');
      menuBtn.on('click', function () {
        $(this).toggleClass('active');
        mainNav.toggleClass('opened');
        mainWrap.toggleClass('no-scroll');
      });
    },
    customScroll: function () {
      if (page.isDev()) {
        jcf.setOptions('Scrollable', {
          'alwaysShowScrollbars': false
        });
        jcf.replace('.main-menu');
      }
      if (!page.isDev()) {
        jcf.setOptions('Scrollable', {
          'alwaysShowScrollbars': false,
          'alwaysPreventMouseWheel': true
        });
        jcf.replace('.popup .text-content');
      }
    },
    resetForm: function () {
      $(document).on('click', '.reset-btn', function () {
        $('.service-message').slideUp();
        $('.contact-form').slideDown();
      });
    },
    serviceMessage: function () {
      $('.contact-form').slideUp();
      $('.service-message').slideDown();
    },
    customForm: function () {
      jcf.replace('textarea');
    },
    animateTopBg: function () {
      $('.top-bg').addClass('animation');
    },
    animate: function () {
      if ($('.no-dev').length) {
        var elements = $('.animated'),
          scrolled = $(window).scrollTop(),
          addHeight = $(window).height() * 0.8;
        for (var i = 0; i < elements.length; i++) {
          var elementPos = $(elements[i]).offset().top;
          if (elementPos < (scrolled + addHeight)) {
            $(elements[i]).addClass('animation');
          }
        }
      }
    },
    checkEdgeBrowser: function () {
      if (/Edge\/\d./i.test(navigator.userAgent)){
        // This is Microsoft Edge
        $(".top-bg").find('h1').css({transform: 'translateY(0)', opacity: '1'});
      }
    },
    load: function () {
      page.animateTopBg();
      page.animate();
    },
    resize: function () {
      page.customScroll();
      page.animate();
    },
    scroll: function () {
      page.stickyHeader();
      page.animate();
    }
  };

  $(document).ready(page.init);
  $(window).on({
    'load': page.load,
    'resize': page.resize,
    'scroll': page.scroll
  });

  window.isDevice = page.isDev;

})(jQuery, window, document);
